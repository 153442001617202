export const LEAD_FIRST = 'Pre-subscribe on Spotify/Apple Music!'
export const LEAD_SECOND =
  'Pre-subscription ensures that the music will be automatically added to your library on release.'
export const NOTE_FIRST = 'In order to use this service, you must consent to our'
export const NOTE_SECOND = ' Privacy Policy '
export const NOTE_THIRD = 'and '
export const NOTE_FOURTH = 'Notice'
export const NOTE_FIFTH = ' Please read them carefully and check the box below if you consent thereto'
export const NOTE_SIXTH = 'Please select your country/region of residence from the list above.'

export const NOTE_SEVENTH = 'This service is available in the following countries/regions.'
export const NOTE_EIGHTH = '   Japan, USA'
export const NOTE_TENTH =
  'The pre-subscription process will not be properly completed if accessed from outside the countries specified.'
export const NOTE_ELEVENTH =
  'Should the use of service by a person residing outside the countries specified be found, all information we have acquired of that person will be deleted.'
export const NOTE_TWELFTH = 'For information regarding cookies, please refer to'
export const NOTE_THIRTEENTH = ' More on Cookies'
export const US_POLICY = 'I consent to the Privacy Policy and Notice.'
