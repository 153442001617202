import {createSlice} from '@reduxjs/toolkit'

const checkListSlice = createSlice({
  name: 'checkList',
  initialState: {
    checkValue: false,
  },
  reducers: {
    setCheckListStateAction(state, {payload}) {
      state.checkValue = payload
    },
  },
})

export const {setCheckListStateAction} = checkListSlice.actions
export default checkListSlice.reducer
