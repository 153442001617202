import './style.css'

import React from 'react'
import {shallowEqual} from 'react-redux'
import {useNavigate} from 'react-router-dom'

import spotifyLogo from '../../assert/icon/text_spotify.svg'
import {useAppSelector} from '../../store'
import {createId} from '../common/createId'

const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID
const authEndpoint = process.env.REACT_APP_SPOTIFY_AUTH_ENDPOINT
const scopes = ['user-read-private', 'user-read-email', 'user-library-read', 'user-library-modify']
const redirectUrl = process.env.REACT_APP_REDIRECT_URI
interface PropsInfo {
  readonly id: string | null
  readonly disableStatus: boolean
}

export const Spotify = (props: PropsInfo) => {
  const navigate = useNavigate()
  const pspaId = props.id
  if (pspaId === null) {
    navigate('/error')
  }

  const {countryCode} = useAppSelector(
    (state) => ({
      countryCode: state.countryCode.countryCode,
    }),
    shallowEqual
  )

  const handleSpotifyLogin = () => {
    const idUserMng = createId()

    const state = pspaId + ',' + idUserMng + ',' + countryCode

    //setTdData(pspaId, idUserMng, 'spotify')

    const loginUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scopes.join(
      '%20'
    )}&response_type=code&state=${state}`

    window.open(loginUrl, '_blank', 'noopener')
  }

  return (
    <button
      className={'p-reservebtn__items' + ' ' + (props.disableStatus ? 'is-spotify-disabled' : 'is-spotify')}
      disabled={props.disableStatus}
      onClick={handleSpotifyLogin}
    >
      <p
        className={
          (props.disableStatus ? 'p-reservebtn__items-is-inner-disabled' : 'p-reservebtn__items-is-inner') +
          ' ' +
          'is-inner-spotify'
        }
      >
        <span>
          <img src={spotifyLogo} className={'is-inner-spotify-span-image'} alt="Pre-Save on Spotify" />
        </span>
      </p>
    </button>
  )
}
