import {CheckboxField} from '@aws-amplify/ui-react'
import React from 'react'
import {ChangeEvent} from 'react'

import {useAppDispatch} from '../../../store'
import {setCheckListStateAction} from '../../../store/features/checkList'

interface policyValue {
  policy: string
}
export const HomeCheckList = (props: policyValue) => {
  const dispatch = useAppDispatch()
  const handlePolicyCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    dispatch(setCheckListStateAction(value))
  }

  return (
    <CheckboxField
      style={{
        letterSpacing: '1px',
        lineHeight: '1.5px',
      }}
      label={props.policy}
      name="policyCheck"
      value="yes"
      onChange={handlePolicyCheck}
      size="small"
    />
  )
}
