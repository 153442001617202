import '../style.css'

import React from 'react'

import {ReplaceURL} from '../../../../utils/replaceUrl'
import {
  LEAD_FIRST,
  NOTE_EIGHTH,
  NOTE_FIFTH,
  NOTE_FIRST,
  NOTE_FOURTH,
  NOTE_SECOND,
  NOTE_SEVENTH,
  NOTE_SIXTH,
  NOTE_THIRD,
} from './text'

export const HomeKR: React.FC = () => {
  const {privacyUrl, noticeUrl} = ReplaceURL('kr')

  return (
    <div className="p-info__reserve-en">
      <div className="p-info__reserve-en-checkcont-defo">
        <div className="p-info-reserve-overseas-home__lead">
          <p>
            {LEAD_FIRST}
            <br />
          </p>
        </div>
        <ul className="p-info-reserve-overseas-home-note">
          <li className="p-info-reserve-overseas-home-note-item">
            ・{NOTE_FIRST}
            <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
              {NOTE_SECOND}
            </a>
            {NOTE_THIRD}
            <a href={noticeUrl} target="_blank" rel="noopener noreferrer">
              {NOTE_FOURTH}
            </a>
            {NOTE_FIFTH}
          </li>
          <li className="p-info-reserve-overseas-home-note-item">・{NOTE_SIXTH}</li>
          <li className="p-info-reserve-overseas-home-note-item">
            ・{NOTE_SEVENTH}
            <a className="ot-sdk-show-settings" href="#ot-sdk-btn">
              {NOTE_EIGHTH}
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
