import './style.css'

import React, {ChangeEvent} from 'react'

import {useAppDispatch} from '../../store'
import {setCountryCodeStateAction} from '../../store/features/country'
import {countryOption} from '../../utils/country'

export const SelectList = () => {
  const dispatch = useAppDispatch()
  const handlerCountryCode = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as string
    dispatch(setCountryCodeStateAction(value))
  }

  return (
    <>
      {/* <label className={isShow ? "pull-down_la-label" : "pull-down_la-label-no-show"}  >Country/region of residence</label> */}
      <select onChange={handlerCountryCode} id="select_lang" className="pull-down_la-select">
        <option className={'pull-down_la-select-option-default'} defaultValue={'-'}>
          Country/region of residence
        </option>
        {countryOption.map((k) => (
          <option className={'pull-down_la-select-option'} key={k.key} value={k.value}>
            {k.labels}
          </option>
        ))}
      </select>
    </>
  )
}
