import './style.css'

import React from 'react'

interface TitleInfo {
  title: string
  caption: string
  releaseDate: string
  isNoneJK: boolean
}

export const Title = (props: TitleInfo) => {
  return (
    <>
      <div className={props.isNoneJK ? 'none-jk-home-p-info-title' : 'p-info-home__title'}>
        <h1 className={props.isNoneJK ? 'none-jk-home-p-info-span' : 'p-info-home__song'}>{props.title}</h1>
        <div className={props.isNoneJK ? 'none-jk-home-p-info-release-date' : 'p-info-home__release'}>
          {props.releaseDate} RELEASE
        </div>
      </div>
      <div className={props.isNoneJK ? '' : 'p-info-reserve-home__lead'}>{props.caption}</div>
    </>
  )
}
