export const createId = (): string => {
  // 日付取得
  const dt: Date = new Date()
  const year: number = dt.getFullYear()
  const month: number = dt.getMonth() + 1
  const date: number = dt.getDate()
  const hours: number = dt.getHours()
  const minutes: number = dt.getMinutes()
  const seconds: number = dt.getSeconds()
  const milliseconds: number = dt.getMilliseconds()
  let sDate: string = String(year) + ('00' + String(month)).slice(-2) + ('00' + String(date)).slice(-2)
  sDate +=
    ('00' + String(hours)).slice(-2) +
    ('00' + String(minutes)).slice(-2) +
    ('00' + String(seconds)).slice(-2) +
    ('00' + String(milliseconds)).slice(-2)

  // ランダム文字列取得
  const len = 10
  const str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const strLen: number = str.length
  let sRandom = ''

  for (let i = 0; i < len; i++) {
    sRandom += str[Math.floor(Math.random() * strLen)]
  }

  // 日付とランダム文字列からid_usermngを作成
  const idUserMng: string = sDate + '_' + sRandom
  return idUserMng
}
