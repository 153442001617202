import '../style.css'

import React from 'react'

import {ReplaceURL} from '../../../../utils/replaceUrl'
import {
  LEAD_FIRST,
  LEAD_SECOND,
  NOTE_EIGHTH,
  NOTE_ELEVENTH,
  NOTE_FIFTH,
  NOTE_FIRST,
  NOTE_FOURTH,
  NOTE_SECOND,
  NOTE_SEVENTH,
  NOTE_SIXTH,
  NOTE_TENTH,
  NOTE_THIRD,
  NOTE_THIRTEENTH,
  NOTE_TWELFTH,
} from './text'

export const HomeUS: React.FC = () => {
  const {privacyUrl, noticeUrl} = ReplaceURL('us')

  return (
    <div className="p-info__reserve-en">
      <div className="p-info__reserve-en-checkcont-defo">
        <div className="p-info-reserve-overseas-home__lead">
          <p>
            {LEAD_FIRST}
            <br />
            {LEAD_SECOND}
          </p>
        </div>
        <ul className="p-info-reserve-overseas-home-note">
          <li className="p-info-reserve-overseas-home-note-item">
            ・{NOTE_FIRST}
            <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
              {NOTE_SECOND}
            </a>
            {NOTE_THIRD}
            <a href={noticeUrl} target="_blank" rel="noopener noreferrer">
              {NOTE_FOURTH}
            </a>
            .{NOTE_FIFTH}.
          </li>
          <li className="p-info-reserve-overseas-home-note-item">・{NOTE_SIXTH}</li>
          <li className="p-info-reserve-overseas-home-note-item">
            ・{NOTE_SEVENTH}
            <br />
            &nbsp;&nbsp;{NOTE_EIGHTH}
          </li>
          <li className="p-info-reserve-overseas-home-note-item">・{NOTE_TENTH}</li>
          <li className="p-info-reserve-overseas-home-note-item">・{NOTE_ELEVENTH}</li>
          <li className="p-info-reserve-overseas-home-note-item">
            ・{NOTE_TWELFTH}{' '}
            <a className="ot-sdk-show-settings" href="#ot-sdk-btn">
              {NOTE_THIRTEENTH}
            </a>
            .
          </li>
        </ul>
      </div>
    </div>
  )
}
