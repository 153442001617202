import './style.css'

import React, {useEffect, useState} from 'react'
import {CSSTransition} from 'react-transition-group'

interface PropsInfo {
  readonly imageUrl: string
}

export const Anime = (props: PropsInfo) => {
  const [isEnter, setIsEnter] = useState(false)
  const [albumImage, setAlbumImage] = useState('')

  const handlerCssState = () => {
    setIsEnter(true)
  }

  useEffect(() => {
    setAlbumImage(props.imageUrl)
  }, [])

  return (
    <>
      <div className="window-size">
        <CSSTransition in={isEnter} timeout={4000} classNames="my-node" style={{width: '100%', height: '100%'}}>
          <img src={albumImage} alt="" onLoad={handlerCssState} />
        </CSSTransition>
      </div>
    </>
  )
}
