import './style.css'

import React from 'react'

import {DynamicTitle} from '../../components/dynamicTitle'
import {Anime} from '../animation/index'
import {SubEnIndex} from './subEnIndes'

interface PropsInfo {
  readonly imageUrl: string
  readonly title: string
  readonly date_release: string
  readonly caption: string
  readonly isNoneJK: boolean
}

export const Instagram = (props: PropsInfo) => {
  return (
    <>
      {props.isNoneJK ? (
        <>
          <DynamicTitle title={props.title} />
          <div className="container">
            <div className="none-jk-instagram-is-load">
              <div className="none-jk-instagram-l-wrap">
                <div className="none-jk-instagram-p-info">
                  <div className="none-jk-instagram-p-info-inner">
                    <SubEnIndex
                      title={props.title}
                      caption={props.caption}
                      date_release={props.date_release}
                      isNoneJK={props.isNoneJK}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <DynamicTitle title={props.title} />
          <div className="is-load-instagram">
            <div className="p-bg-instagram">
              <div className="p-bg-instagram__img" style={{backgroundImage: `url(${props.imageUrl})`}}></div>
            </div>
            <div className="l-wrap-instagram">
              <div className="p-hero-instagram">
                <div className="p-hero__img-instagram">
                  <Anime imageUrl={props.imageUrl} />
                </div>
              </div>
              <div className="p-info-instagram">
                <div className="p-info-instagram__inner">
                  <SubEnIndex
                    title={props.title}
                    caption={props.caption}
                    date_release={props.date_release}
                    isNoneJK={props.isNoneJK}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
